<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">
        Shown below are two different ways to synthesize hydrogen peroxide,
        <stemble-latex content="$\ce{H2O2}.$" />
        Calculate
        <stemble-latex content="$\Delta G^\circ_{\text{rxn}}$" />
        for each of the reactions using the
        <stemble-latex content="$\Delta G^\circ_{\text{f}}$" />
        values provided
        <a
          href="https://openstax.org/books/chemistry-2e/pages/g-standard-thermodynamic-properties-for-selected-substances"
          rel="noopener noreferrer"
          target="_blank"
          >here</a
        >.
      </p>

      <p class="mb-3">
        <stemble-latex content="$\ce{a) H2(g) + O2(g) -> H2O2(l)}$" />
      </p>

      <calculation-input
        v-model="inputs.Grxn1"
        class="mb-8"
        prepend-text="$\Delta G^\circ_{\text{rxn}}:$"
        append-text="$\text{kJ/mol}$"
        :disabled="!allowEditing"
      />

      <p class="mb-3">
        <stemble-latex content="$\ce{b) H2O(l) + 1/2O2(g) -> H2O2(l)}$" />
      </p>

      <calculation-input
        v-model="inputs.Grxn2"
        class="mb-8"
        prepend-text="$\Delta G^\circ_{\text{rxn}}:$"
        append-text="$\text{kJ/mol}$"
        :disabled="!allowEditing"
      />

      <p class="mb-3">
        c) Of these two reactions, which is <b>MORE</b> spontaneous under these conditions?
      </p>

      <v-radio-group v-model="inputs.moreSpontaneous" :disabled="!allowEditing" class="pl-8">
        <v-radio
          v-for="option in options1"
          :key="option.value"
          class="d-inline-block"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.expression" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question281',
  components: {
    CalculationInput,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        Grxn1: null,
        Grxn2: null,
        moreSpontaneous: null,
      },
      options1: [
        {expression: '$\\text{Reaction a}$', value: 'reactionA'},
        {expression: '$\\text{Reaction b}$', value: 'reactionB'},
      ],
    };
  },
};
</script>
